<template>
  <div class="Membership_one">
      <div class="center-two">
        <p class="center-two-first">
          <span>白银会员</span>
          <span>未解锁</span>
        </p>
        <div class="cneter-general">
          <p class="center-two-last">
            <span>邀请新用户</span>
            <span>3/10</span>
          </p>
          <van-progress
            :percentage="30"
            color="linear-gradient(to right, #464F66, #4C5773)"
            stroke-width="10"
          />
          <p class="center-two-load">
            <span>近30天缺货</span>
            <span>3/10</span>
          </p>
          <van-progress
            :percentage="30"
            color="linear-gradient(to right, #464F66, #4C5773)"
            stroke-width="10"
          />
          <p class="cneter-two-tress">
            <span>分享优惠券/店铺/平台链接</span>
            <span>3/500</span>
          </p>
          <van-progress
            :percentage="30"
            color="linear-gradient(to right, #464F66, #4C5773)"
            stroke-width="10"
          />
        </div>
        <div class="cneter-pic">
          <img src="../../static/会员升级_slices/编组 3.png" alt />
        </div>
        <div class="cneter-diamond" v-for="item in pics" :key="item">
          <p>
            <img :src="item.pic" />
          </p>
          <p>{{item.text}}</p>
        </div>
      </div>
      <div class="center-two-pell">
        <p class="center-two-first">
          <span>黄金会员</span>
          <span>未解锁</span>
        </p>
        <div class="cneter-general">
          <p class="center-two-last">
            <span>邀请新用户</span>
            <span>3/10</span>
          </p>
          <van-progress
            :percentage="30"
            color="linear-gradient(to right, #464F66, #4C5773)"
            stroke-width="10"
          />
          <p class="center-two-load">
            <span>近30天缺货</span>
            <span>3/10</span>
          </p>
          <van-progress
            :percentage="30"
            color="linear-gradient(to right, #464F66, #4C5773)"
            stroke-width="10"
          />
          <p class="cneter-two-tress">
            <span>分享优惠券/店铺/平台链接</span>
            <span>3/500</span>
          </p>
          <van-progress
            :percentage="30"
            color="linear-gradient(to right, #464F66, #4C5773)"
            stroke-width="10"
          />
        </div>
        <div class="cneter-pic">
          <img src="../../static/会员升级_slices/编组 3.png" alt />
        </div>
        <div class="cneter-diamond" v-for="item in pics" :key="item">
          <p>
            <img :src="item.pic" />
          </p>
          <p>{{item.text}}</p>
        </div>
      </div>
      <div class="center-two-peller">
        <p class="center-two-first">
          <span>白银会员</span>
          <span>未解锁</span>
        </p>
        <div class="cneter-general">
          <p class="center-two-last">
            <span>邀请新用户</span>
            <span>3/10</span>
          </p>
          <van-progress
            :percentage="30"
            color="linear-gradient(to right, #464F66, #4C5773)"
            stroke-width="10"
          />
          <p class="center-two-load">
            <span>近30天缺货</span>
            <span>3/10</span>
          </p>
          <van-progress
            :percentage="30"
            color="linear-gradient(to right, #464F66, #4C5773)"
            stroke-width="10"
          />
          <p class="cneter-two-tress">
            <span>分享优惠券/店铺/平台链接</span>
            <span>3/500</span>
          </p>
          <van-progress
            :percentage="30"
            color="linear-gradient(to right, #464F66, #4C5773)"
            stroke-width="10"
          />
        </div>
        <div class="cneter-pic">
          <img src="../../static/会员升级_slices/编组 3.png" alt />
        </div>
        <div class="cneter-diamond" v-for="item in pics" :key="item">
          <p>
            <img :src="item.pic" />
          </p>
          <p>{{item.text}}</p>
        </div>
      </div>
    </div>

</template>
<script>
export default {
  name: 'Membership_one',

  data() {
    return {
         pics: [
        {
          pic: "../../static/会员升级_slices (1)/会员.png",
          text: "佣金提升",
        },
        {
          pic: "../../static/会员升级_slices (1)/会员.png",
          text: "隐藏福利券",
        },
        {
          pic: "../../static/会员升级_slices (1)/会员.png",
          text: "邀请返利",
        },
        {
          pic: "../../static/会员升级_slices (1)/会员.png",
          text: "专属客服",
        },
        {
          pic: "../../static/会员升级_slices (1)/会员.png",
          text: "活动福利",
        },
      ]
    };
  },

  mounted() {
    
  },

  methods: {
    
  },
};
</script>
<style lang="less" scoped>
  .Membership_one{
    top: 0;
    left: 0;
    right: 0;
    height: 659.01px;
    background-color:#FFCCCCCC ;
      .center-two {
      height: 372px;
      padding-top: 10px;
      border-radius: 5px;
      background-color: #464f66;
      .center-two-first {
        display: flex;
        justify-content: space-between;

        :first-child {
          font-size: 18px;
          margin-left: 10px;
          color: #ffe5c8;
        }
        :last-child {
          font-size: 12px;
          height: 27px;
          width: 56px;
          color: #5d5d5d;
          background-color: #2c2c2d;
          line-height: 27px;
          text-align: center;
          border-radius: 100px 0px 0px 100px;
        }
      }
      .cneter-general {
        margin: 14px 10px 0 10px;
      }
      .center-two-last,
      .center-two-load,
      .cneter-two-tress {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        color: #ffe5c8;
      }
      .cneter-pic {
        text-align: center;
        margin-top: 20px;
      }
      .cneter-diamond {
        float: left;
        width: 25%;
        text-align: center;
        font-size: 10px;
        color: #ffcccccc;
      }
    }
    .center-two-pell {
      height: 372px;
      padding-top: 10px;
      border-radius: 5px;
      background-color: rgba(122, 96, 73, 1);
      .center-two-first {
        display: flex;
        justify-content: space-between;

        :first-child {
          font-size: 18px;
          margin-left: 10px;
          color: #FFFFE5C8;
        }
        :last-child {
          font-size: 12px;
          height: 27px;
          width: 56px;
          color: #5d5d5d;
          background-color: #2c2c2d;
          line-height: 27px;
          text-align: center;
          border-radius: 100px 0px 0px 100px;
        }
      }
      .cneter-general {
        margin: 14px 10px 0 10px;
      }
      .center-two-last,
      .center-two-load,
      .cneter-two-tress {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        color: #ffe5c8;
      }
      .cneter-pic {
        text-align: center;
        margin-top: 20px;
      }
      .cneter-diamond {
        float: left;
        width: 25%;
        text-align: center;
        font-size: 10px;
        color: #ffcccccc;
      }
    }
    .center-two-peller {
      height: 372px;
      padding-top: 10px;
      border-radius: 5px;
      background-color: rgba(95, 95, 95, 1);
      .center-two-first {
        display: flex;
        justify-content: space-between;

        :first-child {
          font-size: 18px;
          margin-left: 10px;
          color: #ffe5c8;
        }
        :last-child {
          font-size: 12px;
          height: 27px;
          width: 56px;
          color: #5d5d5d;
          background-color: #2c2c2d;
          line-height: 27px;
          text-align: center;
          border-radius: 100px 0px 0px 100px;
        }
      }
      .cneter-general {
        margin: 14px 10px 0 10px;
      }
      .center-two-last,
      .center-two-load,
      .cneter-two-tress {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        color: #ffe5c8;
      }
      .cneter-pic {
        text-align: center;
        margin-top: 20px;
      }
      .cneter-diamond {
        float: left;
        width: 25%;
        text-align: center;
        font-size: 10px;
        color: #ffcccccc;
      }
    }
  }
</style>